import React from 'react'
import { Gatsby, ReactJs, Kubernetes, Neovim } from '@icons-pack/react-simple-icons'

const About = (props) => {
  return (
    <div>
      <ReactJs color="#61DAFB" size='0.8rem' />{' '}
      <Kubernetes color="blue" size='0.8rem' />{' '}
      <Neovim color="green" size='0.8rem' />{' '}
      <Gatsby color="rebeccapurple" size='0.8rem' />{' '}
    </div>
  )
}

export default About
